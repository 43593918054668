@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css');
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.2/font/bootstrap-icons.min.css');

* {
  margin: 0;
  padding: 0;
}

body {
  max-width: 1280px;
  margin: 0 auto;
}